import React, { FC, useState, useRef } from "react";
import Vimeo from "@vimeo/player";

import { VideoTypes, processVideoUrl } from "utils/processVideoUrl";

import {
  Button,
  TransparentBackground,
  VimeoPlayerElement,
  Wrapper,
} from "./styled";

const optionsVimeo = {
  app_id: 122963,
  autoplay: false,
  title: false, // hide the title
  byline: false, // hide the byline
  portrait: false, // hide the user portrait
  controls: true,
};

export const Player: FC<{ url?: string; thumbnail?: string }> = ({
  url,
  thumbnail,
}) => {
  const vimeoPlayerRef = useRef(null);
  const s3PlayerRef = useRef(null);

  const vimeoPlayer = useRef(null);

  const media = processVideoUrl(url);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const handlePlay = () => {
    if (media.type === VideoTypes.Vimeo) {
      vimeoPlayer.current = new Vimeo(vimeoPlayerRef.current, {
        url: `https://vimeo.com/${media.id}`,
        ...optionsVimeo,
      });

      vimeoPlayer.current.on("loaded", () => {
        vimeoPlayer.current.play();
        vimeoPlayer.current.requestFullscreen();
      });

      setIsLoaded(true);
      return;
    }

    if (media.type === VideoTypes.S3) {
      const video = s3PlayerRef.current;

      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }

      video.play();

      setIsLoaded(true);
      return;
    }
  };

  const vimeoElement = media.type === VideoTypes.Vimeo && (
    <VimeoPlayerElement ref={vimeoPlayerRef} />
  );

  const s3Element = media.type === VideoTypes.S3 && (
    <video ref={s3PlayerRef} src={media.url} controls={isLoaded} />
  );

  return (
    <Wrapper id="player-wrapper">
      {vimeoElement}

      {s3Element}

      {!isLoaded && <TransparentBackground thumbnail={thumbnail} />}

      {!isLoaded && <Button onClick={handlePlay} />}
    </Wrapper>
  );
};
